import './App.scss';
import React from 'react';
import Web3 from 'web3'; 
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { tokens } from './configs/CultTokensConfig.js'
import { nfts } from './configs/CultNFTConfig.js'

//import door from './imgs/door.gif'
//import famgif from './imgs/fam.gif'

import door from './imgs/doorslow.gif'
//import famgif from './imgs/famslow.gif'

//import door from './imgs/doorslowbig.gif'
import famgif from './imgs/famsmooth.gif'

import dustgif from './imgs/dust.gif'
const fs = (bal, dec = 18) => {
  let x = bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  output = new Intl.NumberFormat().format(output)
  return output
}

function App() {
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");

 
  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (!connected) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      }
  }

  
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])



  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  

  const [isDisabled, setDisabled] = React.useState(false);

  // this is a reference to the input field
  const theInputRef = React.createRef();


  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.ftm.tools'));


  var a2 = new web3.eth.Contract(
    nfts["a2"]["abi"], 
    nfts["a2"]["address"])

  const letItRip = () => {
    console.log("let it rip")
    

  }

  



  const userRef = React.createRef()
  const [activeAddress, setActiveAddress] = React.useState("")
  

  const [isRegistered, setIsRegistered] = React.useState(false)
  const [ap, setAP] = React.useState(0)
  const [cp, setCP] = React.useState(0)

  const register = () => {
    
    a2.methods.getUserInfo(userRef.current.value)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setIsRegistered(res[0])
        setAP(Number(res[1]))
        setCP(Number(res[2]))
        setActiveAddress(userRef.current.value)
        if (!res[0]) {
          let data = a2.methods.register(userRef.current.value).encodeABI()
          window.ethereum.request({
            method: "eth_sendTransaction",
            params: [
              {
                from: window.ethereum.selectedAddress,
                to: nfts["a2"]["address"],
                value: 0,
                data: data,
              },
            ],
          })
        } else {
          alert("already registered")
        }
      })

  }


  return (
    <div className="App">
        
        <div className="a2Manager">
          <h2>Register</h2>
          <p>Register yourself or others for Airdrop #2.</p>
          <p>Enter the user's address below and submit the transaction.</p>
          <input ref={userRef} /><button onClick={register}>Submit</button>
         
      
          
        </div>

        <div className="a2-score">
          <h2>Airdrop #2 for {activeAddress}</h2>
          <p>If you just registered, please wait while we review your account.</p>
          <div className="points">
            <p>Activity Points</p>
            <p>{ap}</p>
          </div>
          <div className="points">
            <p>Scaled Activity Points</p>
            <p>{Math.round(66000*ap/11641)}</p>
          </div>
          <div className="points">
            <p>Community Points</p>
            <p>{cp}</p>
          </div>
          <div className="points">
            <p>Total Points</p>
            <p>{cp + Math.round(66000*ap/11641)}</p>
          </div>
          <div className="points">
            <p>Estimated $FAM</p>
            <p>{200*(cp + Math.round(66000*ap/11641))/100000}</p>
          </div>
        </div>

        <div className="a2Info">
          <h1>$FAM Airdrop #2 Info</h1>
          <p>200 $FAM will be distributed in Airdrop #2.</p>
          <h2>Activity Points</h2>
          <p>Users will receive Activity Points based on their activity during the timeframe of April 26th-May 5th.</p>
          <p>You will submit your address, and it will be reviewed manually for the following activity:</p>
          <ul>
            <li>Purchased Cult NFTs on Paintswap (Skully, PopSkully, Ghostly)</li>
            <li>Minted PopSkullys</li>
            <li>Claimed CULT</li>
            <li>Claimed xCULT</li>
            <li>Minted 1155 Items</li>
            <li>Provided CANDLE/FTM LP</li>
          </ul>
          <p>Points will be rewarded for each of these activities. The total points rewarded for this portion of the airdrop is 66,000 Points. Each user's points will be scaled so that the total points are 66,000.</p>
          <h2>Community Points</h2>
          <p>Separate from that portion of the reward, users can receive community points for various things including the presale puzzle.</p>
          <ul>
            <li>Puzzle: 14,000 Points</li>
            <ul>
              <li>8,400 points: rewarded to puzzle solvers</li>
              <li>5,600 points: rewarded to lore completers</li>
            </ul>
            <li>Funeral's Favor: 10,000 Points</li>
            <ul>
              <li>1,500 Points: 500 Points are rewarded to each of the three Apprentices</li>
              <li>8,500 Points: Funeral will be rewarding users throughout this week</li>
            </ul>
            <li>Governance Votoors: 5,000 Points</li>
            <li>Testoors: 5,000 Points</li>
          </ul>
       
     

          
        </div>
        

        <div className={"pending pending--" + isPending}>Pending Transaction</div>
        <button 
          disabled={isDisabled} 
          onClick={connectMM} 
          className="mmbtn">
          {mmBtnText}
        </button>
    </div>
  );
}

export default App;
